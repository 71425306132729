import * as React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'

const GetInTouch = () => {
	return (
		<div className="mx-auto py-10 max-w-2xl">
			<p className="text-center text-2xl">Get in touch</p>
			<p className="text-center text-2xl">
				<a href="https://www.instagram.com/artfulsouth/" target="_blank" rel="noreferrer">
					<FontAwesomeIcon icon={faInstagram} color="#E83F6F" />
					<small className="text-sm">&nbsp;Instagram</small>
				</a>
			</p>
		</div>
	)
}

export default GetInTouch

