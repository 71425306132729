import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"

import GetInTouch from "../components/getInTouch";

const IndexPage = () => (
	<Layout slim={true}>
		<Seo />
		<div className="mx-auto max-w-2xl pt-10 px-4">
			<h1 className="text-4xl">Artful South</h1>
			<h2>by Christina Babin</h2>
			<hr />
		</div>

		<GetInTouch />

		<div className="bg-onyx py-10">
			<div className="mx-auto max-w-3xl text-center">
				<h3 className="text-2xl">Showcase</h3>
			</div>
			<div className="mx-auto max-w-3xl text-center">
				<StaticImage
					src="../images/murals/la-carreta-2.jpg"
					width={960}
					quality={85}
					formats={["jpg", "webp", "avif"]}
					alt=""
					className="mt-10"
				/>
				<p className="text-xl">La Carreta</p>
				<p className="text-base">Louisiana, 2021</p>
				<StaticImage
					src="../images/murals/la-carreta.JPG"
					width={960}
					quality={85}
					formats={["jpg", "webp", "avif"]}
					alt=""
					className="mt-10"
				/>
				<p className="text-xl">La Carreta</p>
				<p className="text-base">Louisiana, 2020</p>
			</div>
		</div>

		<div className="bg-blue py-10">
			<div className="mx-auto max-w-2xl">
				<h1 className="text-4xl text-center mb-10 px-4">Who is Christina?</h1>
				<StaticImage
					src="../images/portrait.png"
					width={840}
					quality={85}
					formats={["jpg", "webp", "avif"]}
					alt="A Photograph of Christina Babin"
					className="mb-4"
				/>
				<p className="px-4">
					I have worked as a professional artist for more than 20 years, specializing in acrylics. Most works include canvas paintings, murals, and theatre set design. It is my goal to create unique and memorable experiences for everyone involved. In this quest for quality experiences, I have ventured into fantasy make-up and body painting, party/event planning, and more.
				</p>
			</div>
		</div>

		<GetInTouch />

		<div className="mb-10" />
	</Layout>
)
/*
<StaticImage
	src="../images/gatsby-astronaut.png"
	width={300}
	quality={95}
	formats={["auto", "webp", "avif"]}
	alt="A Gatsby astronaut"
	style={{ marginBottom: `1.45rem` }}
/>*/

export default IndexPage
